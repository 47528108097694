import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f55a72a = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _1cb55b3c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _ad4682a0 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _4854a6bd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7988ea5e = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _1e149d34 = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _6dff0a4d = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3abfa4a0 = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _1799cdfb = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _7f12d2bc = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _d87fe49e = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _a5fea734 = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _a5fe71f2 = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _7d85d274 = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _46197680 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _4bbb4e06 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _4412952f = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _8b238118 = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _3ef8e797 = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _5146aada = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _4a4a819c = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _fa8a8066 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _315d9f00 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _1688b76c = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _7f4da04a = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _6e96fa23 = () => interopDefault(import('../pages/top-locations/city-park-visitor-center.vue' /* webpackChunkName: "pages/top-locations/city-park-visitor-center" */))
const _4feecda0 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _574e5868 = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _7cbf3622 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _39844073 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _4a89ae6e = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _147f0b13 = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _9eb2abc8 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _fa6c0c32 = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _ab3bed86 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _9d574e8e = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _74576096 = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _96edce60 = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _dc4b5c5e = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _536af734 = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _469e0575 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _8f55a72a,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _1cb55b3c,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _ad4682a0,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _4854a6bd,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _7988ea5e,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _1e149d34,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _6dff0a4d,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _3abfa4a0,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _1799cdfb,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _7f12d2bc,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _d87fe49e,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _a5fea734,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _a5fe71f2,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _7d85d274,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _46197680,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _ad4682a0,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _7d85d274,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _1e149d34,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _7988ea5e,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _3abfa4a0,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _7f12d2bc,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _a5fe71f2,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _d87fe49e,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _1cb55b3c,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _46197680,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _6dff0a4d,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _a5fea734,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _8f55a72a,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _1799cdfb,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _4bbb4e06,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _4412952f,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _8b238118,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _3ef8e797,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _5146aada,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _4a4a819c,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _fa8a8066,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _315d9f00,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _1688b76c,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _7f4da04a,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosliget-latogatokozpont",
    component: _6e96fa23,
    name: "top-locations-city-park-visitor-center___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _4feecda0,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _574e5868,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _8b238118,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _4bbb4e06,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _4412952f,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _3ef8e797,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _5146aada,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/city-park-visitor-center",
    component: _6e96fa23,
    name: "top-locations-city-park-visitor-center___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _4a4a819c,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _fa8a8066,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _315d9f00,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _1688b76c,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _7f4da04a,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _4feecda0,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _574e5868,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _7cbf3622,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _39844073,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _4a89ae6e,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _147f0b13,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _9eb2abc8,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _fa6c0c32,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _ab3bed86,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _9d574e8e,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _74576096,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _96edce60,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _dc4b5c5e,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _536af734,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _7cbf3622,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _39844073,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _4a89ae6e,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _469e0575,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _9eb2abc8,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _fa6c0c32,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _536af734,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _ab3bed86,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _96edce60,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _74576096,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _dc4b5c5e,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _9d574e8e,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _147f0b13,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _4854a6bd,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _469e0575,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
